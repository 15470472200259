////
/// Hide Show
////

.expandmore__button {
  position: relative;
  display: block;
  padding-right: 2em;
  font-size: 100%;
  border: none;
  outline: none;
  background: none;
}

.expandmore__button::before {
  content: counter(legend)'. ';
  counter-increment: legend;
}

.expandmore__button::after {
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  padding: 0 0.3em;
  width: 35px;
  height: 31px;
  transition: all 0.3s ease-out 0s;
  transform: rotate(0deg);
  transform-origin: center center;
  color: #000;
  background: url('../svg/picto-toggle.svg') no-repeat center center / cover;
}

/* title opened */

.is-opened + .expandmore__to_expand:not(:last-of-type) {
  border-bottom: 1px solid;
}

.is-opened .expandmore__button::after {
  transform: rotate(180deg);
  transform-origin: 50% 50%;
}

/* This is the opened state */
.is-opened + .expandmore__to_expand {
  margin-bottom: $base-line;
  max-height: 150em;
  overflow: auto;
}
/* This is the hidden state */
.expandmore__to_expand {
  display: block;
  overflow: hidden;
  max-height: 0;
  transition: max-height 1.25s ease;
}

/*
Conditional show/hide
*/
.display-none {
  display: none !important;
}
.display-flex {
  display: flex !important;
}