@mixin sassy-checkbox {
  position: absolute;
  left: -1000rem;

  + label,
  + .hf-warning + label  {
    position: relative;
    cursor: pointer;
    margin-right: $base-line;
    padding-left: $base-line*3/2;
    line-height: 1.2;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: $base-em * $ms2 - 0.15em;
      width: $base-em * $ms2 - 0.15em;
      border-radius: 3px;
      background-color: #fff;
      font-family: sans-serif;
      border: 1px solid $sassy-base-color;
    }

    &::after {
      content: '';
      opacity: 0;
      transition: opacity 0.3s;
    }
  }

  /* Not depending on the user's font here improves consistency. */
  &:checked + label::after,
  &:checked + .hf-warning + label::after {
    content: '';
    position: absolute;
    display: block;
    opacity: 1;
    left: 0.42em;
    top: 0;
    width: 0.3em;
    height: 0.95em;
    border: solid $sassy-accent-color;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }

  &:focus + label::before {
    border-color: $sassy-accent-color;
  }

  &:disabled {
    + label {
      cursor: not-allowed;

      &::before {
        background-color: $sassy-disabled-color;
      }
    }

    &:checked + label::after {
      border-color: darken($sassy-disabled-color, 30%);
    }
  }
}
