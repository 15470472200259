.modal {
  display: none;
  &.open {
    display: block;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 25rem;
    transform: translate3d(-50%, -50%, 0);
    background: $color-grey-light;
    padding: 4px;
    z-index: 10;
    border-radius: 4px;
    border-color: $color-grey;
    border-width: 0.1px;
    border-style: solid none none solid;
    -webkit-box-shadow: 5px 5px 3px 4px $color-grey;
    box-shadow: 5px 5px 3px 4px $color-grey;
  }
}

.modal-header {
  background: white;
  border-radius: 4px;
  padding: 2px 16px;
  margin: 5px;
  text-align: center;

  h3 {
    margin: 1em;
  }
}

.modal-body {
  padding: 6px;
  color: $color-grey-verydark;
  text-align: center;

  .modal-text {
    margin-top: 1em;
    margin-bottom: 2em;
  }
}