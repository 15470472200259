///
/// Form
///

@import 'form-inputs/legend';
@import 'form-inputs/errors';

@import 'form-inputs/text';
@import 'form-inputs/buttons-action';
@import 'form-inputs/button';

@import 'form-inputs/radio';
@import 'form-inputs/checkbox';

@import 'form-inputs/select';

// Alert & message

.symbol-required, .error {
  color: $danger;
}

.form-note, .form-info {
  clear: both;
  margin-bottom: $base-line*3/2;
  line-height: $ms2;
}

.form-note {
  color: $color-grey-verydark;
}

.form-more, .form-description {
  display: block;
  margin: 0 0 $base-line/2;
  font-size: $smaller;
  color: #666;
}

.form-more {
  font-size: $smaller;
}

// Form

form {
  margin: 0 auto;
  clear: both;

  div {
    margin-bottom: $base-line;
  }
}


// Fieldset

fieldset, .fieldset {
  position: relative;
  margin: 0 auto $base-line;
  padding: $base-line 0 0;
  border: none;
}

.fieldset-radio {
  margin: 0 0 $base-line;
  padding: $base-line/2 0;
  width: 100%;
  text-align: left;
  border: none;
  background: none;

  img {
    display: inline-block;
    vertical-align: sub;
  }
}

// Legend

legend, .legend, .animated-expandmore__button {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  width: 100%;
  font-family: $title-font-name;
  font-size: $title3;
  font-weight: bold;
  text-align: left;
  color: $color-primary;

  & + * {
    margin-top: 0;
  }
}

.fieldset-radio .legend, .legend-small {
  margin: $base-line/3 0;
  padding: 0;
  padding-right: $base-line;
  width: auto;
  font-family: $base-font-family;
  font-size: $normal;
  font-weight: normal;
  color: #000 !important;
  @media #{$medium-up} {
    display: inline-block;
  }

  &:before {
    display: none;
  }

  &.legend-strong {
    display: block;
    width: 100%;
    font-weight: bold;
  }
}

// Form subscription

.form-subscription {
  margin-right: auto;
  margin-left: auto;
  max-width: 35em;

  fieldset, .fieldset {
    legend {
      text-align: center;
    }
  }
}

// Input

@media #{$medium-up} {
  // if Supports grid
  @supports (display: grid) {
    .form-grid {
      display: grid !important;
    }
  }

  // IE11 if Supports grid !! NOT working !!
  /* @supports (display: -ms-grid) {
    .form-grid {
      display: -ms-grid !important;
    }
  } */

  // Class for declaring grid
  .form-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 0 1em;

    legend, .form-note, > *, > *:nth-child(5) {
      grid-column: 1 / 7;
      width: 100%;
    }

    .form-grid-50-left {
      grid-column: 1 / 4;
    }

    .form-grid-50-right {
      grid-column: 4 / 7;
    }

    .form-grid-30-left {
      grid-column: 1 / 3;
    }

    .form-grid-30-middle {
      grid-column: 3 / 5;
    }

    .form-grid-30-right {
      grid-column: 5 / 7;
    }

    .form-grid-50-left {
      grid-column: 1 / 4;
    }

    .form-grid-60-left {
      grid-column: 1 / 5;
    }

    .form-grid-60-right {
      grid-column: 3 / 7;
    }

    .form-grid-70-left {
      grid-column: 1 / 6;
    }
    
    .form-grid-70-right {
      grid-column: 3 / 7;
    }
  }
}

// Align text in input
.form-align {
  input, .input {
    text-align: left;
    @media #{$medium-up} {
      min-width: 200px;
      max-width: 200px;
      text-align: center;
    }
  }
}

// MY ADD
.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline input {
  vertical-align: middle;
  margin: 5px 10px 5px 0;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
}

.form-inline select {
  display: inline-block;
  vertical-align: middle;
  margin: 5px 10px 5px 0;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  width: auto;
}
// END MY ADD

// All elements except 2
form p:not(.form-note):not(.form-submit) {
  position: relative;
  display: flex;
  align-items: baseline;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: $base-line*3/2;
  text-align: left;

  &:first-child {
    margin-top: 2em;
  }
  &.mt2 {
    @media #{$medium-up} {
      margin-top: 2em;
    }
  }

  &.mb0 {
    margin-bottom: 0;
  }

  @media #{$medium-up} {
    flex-direction: row;
    // Only of grid is supported
    @supports (display: grid) {
      .label-max {
        min-width: 150px;
      }
    }
  }

  > label {
    order: 1;
    padding-right: 1em;
    min-width: 150px;

    @media #{$small-up} {
      flex: 1;
    }

    @media #{$medium-up} {
      @supports (display: grid) {
        min-width: auto;
      }
      white-space: nowrap;
    }
  }


  > input, > .input, > textarea {
    display: block;
    flex: 99;
    order: 2;
    min-width: 10px;
  }

  .input {
    padding: 0 $base-line/2;
    font-weight: bold;
    color: $color-primary;
    background-color: $color-grey-light;
  }

  > .form-description {
    flex: 0 1 100%;
    order: 99;
  }

  label, select, textarea, .input, input:not([type='submit']) {
    display: block;
    width: 100%;
    height: auto;
  }

  // Add type of value after an input
  &[data-value-type]::before {
    content: attr(data-value-type);
    order: 2;
    padding: 0 $base-line/2;
    margin-bottom: -1.8em;
    z-index: 10;
    width: 100%;
    text-align: right;
    @media #{$small-up} {
      position: absolute;
      right: 0;
      width: auto;
    }
    @media #{$medium-up} {
      position: relative;
      order: 3;
    }
  }

  input[type=checkbox], input[type=radio] {
    display: inline-block;
    width: auto;
    vertical-align: baseline;
  }

  form > fieldset > &:last-of-type {
    margin-bottom: $base-line*2;
  }
}

.form-input__small.form-input__small {
  display: inline-block;
  width: auto;
  max-width: 80px;
  text-align: center;
  vertical-align: baseline;
}

.form-checkbox {
  padding: $base-line*3 0 0;
  text-align: center;
}

.form-submit {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: $base-line*3 auto $base-line*2;
  text-align: center;
}

input[type=text], input[type=url], input[type=tel], input[type=date], input[type=email], input[type=password], input[type=number] {
  @include sassy-text;
}

input[type=text], input[type=url], input[type=date], input[type=email], input[type=password] {
  @include sassy-text-control;
}

input[type=number] {
  @include sassy-number-control;
}

textarea {
  @include sassy-textarea;
}

input[type=search] {
  @include sassy-search;
}

input[type=checkbox], input[type=checkbox] {
  @include sassy-checkbox;
}

input[type=radio] {
  @include sassy-radio;
}

[role=button], [type=submit], .button {
  @include sassy-button;

  & + & {
    margin-left: $base-line/2;
  }
}

.select-and-button {
  display: flex;
}

.regular-label-for-radios {
  order: 0 !important;
  flex-grow: 0 !important;
}

.no-order {
  order: 0 !important;
}

.align-items-center {
  align-items: center !important;
}