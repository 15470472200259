@mixin sassy-text {
  box-sizing: border-box;

  padding: $base-line/4;
  font-family: inherit;
  font-size: 1em;
  vertical-align: middle;

  border: 1px solid lighten($sassy-base-color, 0%);
  outline: none;

  z-index: 10;

  &:focus {
    border-color: $sassy-accent-color;
  }

  &:optional {
    // border-color: $color-grey-light;
    border-color: $color-grey-dark;
  }

  &:required {
    border-color: $color-grey-dark;
  }

  &:disabled {
    background-color: $sassy-disabled-color;
    cursor: not-allowed;
  }


}

@mixin sassy-number-control {
  fieldset &,
  .fieldset & {
    &:not(:placeholder-shown):in-range {
      box-shadow: 0 0 1px 0px mix(#fff, $success, 60%);
      border-color: mix(#fff, $success, 60%);
    }
    &:not(:placeholder-shown):out-of-range {
      box-shadow: 0 0 1px 0px mix(#fff, $danger, 60%);
      border-color: mix(#fff, $danger, 60%);
    }
  }
}

@mixin sassy-text-control {
  &:focus {
    fieldset &,
    .fieldset & {
      & {
        box-shadow: 0px 0px 6px 0px $danger;
        border-color: $danger;
      }
      &:not(:placeholder-shown) {
        box-shadow: 0 0 3px 0px $success;
        border-color: mix(#fff,$success,10%);
      }
      &:invalid {
        box-shadow: 0 0 1px 1px $danger;
        border-color: $danger;
      }
    }
  }

  fieldset &,
  .fieldset & {
    &:not(:placeholder-shown):valid {
      box-shadow: 0 0 1px 0px mix(#fff,$success,60%);
      border-color: mix(#fff,$success,60%);
    }
  }
}

@mixin sassy-search {
  @include sassy-text;
  @include sassy-text-control;
  -webkit-appearance: none;
     -moz-appearance: none;
}

@mixin sassy-textarea {
  @include sassy-text;

  display: block;
  overflow: auto;
  background-size: 4% 38%;
}
