////
/// Very basic styles
////

html,
body {
  box-sizing: border-box;
  margin: 0;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: auto !important;
}

// Images
// -------------------------

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border: 0;
  -ms-interpolation-mode: bicubic;
}

// We need to revert these for Google maps cause else it breaks!
%img--no-max,
.img--no-max {
  max-width: none;
}


// Figures
// -------------------------

figure > img {
  display: block;
}

figcaption {
  font-size: $smaller;
}

// iFrame
// -------------------------

iframe,
embed,
object,
video {
  max-width: 100%;
}

// Links
// -------------------------

a {
  text-decoration: underline;
  color: $link-color;
  &:visited {
    color: $link-color;
  }
  &:hover {
    text-decoration: none;
  }
  &:focus {
    outline: thin dotted;
  }
  &:hover, &:active {
    outline: none;
  }
}

// Lists
// -------------------------

ul,ol {
  margin-bottom: $base-line;
  li {
    margin-bottom: 0.5rem;
  }
}

// List options
// -------------------------

// Unstyled keeps list items block level, just removes default browser padding and list-style
.list-unstyled {
  padding-left: 0;
  list-style: none;
}

// Inline turns list items into inline-block
.list-inline {
  @extend .list-unstyled;

  > li {
    display: inline-block;
    margin: 0 -5px 0 0;
  }
}

// Description Lists
// -------------------------

dl {
  margin-top: 0; // Remove browser default
  margin-bottom: $base-line;
}

dt,
dd {
  line-height: $line-height-base;
}

dt {
  font-weight: bold;
}

dd {
  margin-left: 0; // Undo browser default
}

.primary-color {
  color: #4391b1;
}