////
/// Typography
////

// Our variables
//-------------------------------------

$base-font-name:          system !default;
$title-font-name:         'Oswald' !default;
$base-font-family:        $base-font-name, system, sans-serif !default;
$title-font-family:       $title-font-name, system, sans-serif !default;

$ratio:                   1.20;
$base:                    1;
$base-em:                 $base * 0.875em;
$base-px:                 $base * 14px;

$ms0:                     1;
$ms1:                     $ratio;
$ms2:                     $ratio * $ms1;
$ms3:                     $ratio * $ms2;
$ms4:                     $ratio * $ms3;
$ms5:                     $ratio * $ms4;
$ms6:                     $ratio * $ms5;
$ms7:                     $ratio * $ms6;

$smaller:                 $base-em / $ms1;
$normal:                  $base-em * $ms1;
$bigger:                  $base-em * $ms2;
$title3:                  $base-em * $ms3;
$title2:                  $base-em * $ms4;
$title1:                  $base-em * $ms5;

$line-height-base:        $base * $ms2;
$base-line:               floor(($base-em * $line-height-base));

body {
  font-family: $base-font-family;
  font-size: $base-em * $ms0;
  line-height: $base * $ms3;
  //font-size: calc(4vw + 4vh + 2vmin)
}

h3,
h2 { line-height: $base * $ms1; }
h1 { line-height: $base * $ms0; }

h6, .h6 { font-size: $smaller; }
h5, .h5 { font-size: $normal; }
h4, .h4 { font-size: $bigger; }
h3, .h3 { font-size: $title3; }
h2, .h2 { font-size: $title2; }
h1, .h1 { font-size: $title1; }

h1, .h1,
h2, .h2,
h3, .h3,
legend {
  @if $title-font-name {
    font-family: $title-font-family;
  }
}

h1, .h1,
h2, .h2,
h3, .h3 {
  word-wrap: break-word;
  margin-top: $base-line;
  margin-bottom: ($base-line / 2);

  small,
  .small {
    font-size: 65%;
  }
}

h4, .h4,
h5, .h5,
h6, .h6 {
  margin-top: ($base-line / 2);
  margin-bottom: ($base-line / 2);

  small,
  .small {
    font-size: 75%;
  }
}

h1,h2,h3,h4,h5,h6 {
  color: currentColor;
  &,a,a:visited {
    color: currentColor;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
}

h2 {
  color: $color-primary;
}

p {
  margin: 0 0 ($base-line / 2);
}

.paragraphe-big {
  font-family: $title-font-name;
  font-size: $title3;
  font-weight: bold;
}

.link-external {
  display: inline-block;
  margin-left: 5px;
  &::after {
    content: '';
    display: inline-block;
    margin-left: 2px;
    width: 32px;
    height: 32px;
    vertical-align: middle;
    background-image: url('data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'12\' height=\'12\' viewBox=\'0 0 24 24\' fill=\'none\' stroke=\'%23000000\' stroke-width=\'2\' stroke-linecap=\'square\' stroke-linejoin=\'bevel\'%3E%3Cg fill=\'none\' fill-rule=\'evenodd\'%3E%3Cpath d=\'M18 14v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8c0-1.1.9-2 2-2h5M15 3h6v6M10 14L20.2 3.8\'/%3E%3C/g%3E%3C/svg%3E');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
  }
}
