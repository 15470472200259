@mixin sassy-button {
  box-sizing: border-box;

  cursor: pointer;
  display: inline-block;
  display: inline-flex;
	align-items: center;
  justify-content: center;
  min-width: 10em;
  padding: $base-line/2 $base-line;
  font-family: $title-font-family;
  font-size: 1em;
  letter-spacing: 2px;
  text-decoration: none;
  font-weight: normal;
  color: #fff;
  transition: background .45s;
  border-radius: 30px;
  border-width: 2px;
  border-color: #000;
  background: #000;

  &:hover,&:active,&:visited {
    text-decoration: none;
    outline: none;
    color: #000;
    background: #fff;
  }

  &:disabled {
    color: darken($sassy-disabled-color, 30%);
    background-color: $sassy-disabled-color;
    cursor: not-allowed;

    &:hover, &:focus {
      box-shadow: none;
    }
  }
  &[class^="button--"] {
    color: #fff;
  }
}

.delete-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
	-moz-appearance: none;
  appearance: none;
  background: $danger;
  color: white;
  border: solid 1px $danger;
  width: 1.3333em;
  height: 1.25em;
  vertical-align: middle;
  padding: 0 0 1px 0;
  font-size: 1.25em;
  font-weight: bold;
  line-height: 0;
  cursor: pointer;
  &:hover {
    background: white;
    color: $danger;
  }
}
.select-and-button {
  button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: black;
    color: white;
    border: solid 1px black;
    border-left: none;
    vertical-align: middle;
    padding: 0;
    font: normal .85714em/1 system-ui;
    padding: 0 .5em;
    cursor: pointer;
    &:hover {
      background: white;
      color: black;
      border-color: #777;
    }
  }
}