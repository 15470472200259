////
/// Alert confirmation message with hyperform
////

.hf-warning {
  margin: 0;
  padding: 0 $base-line/2;
  font-size: $smaller;
  color: #fff;
  background: $danger;

  p[data-value-type] & {
    right: 2.4em !important;
  }

  .form-checkbox &, .form-radio {
    position: absolute;
    top: 110%;
    clear: both;
    width: 100%;
    text-align: center;
    color: $danger;
    background: transparent;
  }
}

.global-error {
  margin: 0;
  padding: 0 $base-line/2;
  color: $danger;
  background: #fff;
}

p:not(.form-checkbox) .hf-warning {
  order: 3;
  transform: translateX(0);

  @media #{$medium-up} {
    position: absolute;
    z-index: 99;
    right: 0;
    bottom: 97%;
    bottom: calc(100% - 1px);
    margin: 0;
  }

  & + .form-description {
    display: none;
    visibility: hidden;

    @media #{$medium-up} {
      display: block;
      visibility: visible;
    }
  }
}
