// site color
//-------------------------------------

$color-primary:           #4391b1;
$color-primary-light:     #8de1eb;
$color-primary-dark:      #19748a;

$color-secondary:         #fec400;
$color-secondary-dark:    #d29e00;
$color-secondary-light:   #ffd900;

$color-grey:              #a7abbb;
$color-grey-light:        #e8ecf3;
$color-grey-dark:         #7d7d7d;
$color-grey-verydark:     #1e222b;

$title-color:             #111 !default;
$title-link-color:        #111 !default;
$text-color:              #111 !default;
$link-color:              #111 !default;

$default:              	  transparent;
$success:                 #279c38;
$danger:            	    #c0392b;
$warning:            		  #d29e00;
$info:              	    #19748a;

$sassy-base-color:        #777 !default;
$sassy-accent-color:      #111 !default;
$sassy-disabled-color:    #eee !default;
