////
/// Layout
////

body {
  font-family: sans-serif;
  counter-reset: legend;
}

$layout-max-width: 60em !default;
$column-gutter: $base-em;
$column-number: 4 !default;

// Page & Wrapper
// -------------------------

.page {
  padding-bottom: $base-line*4;
}

.page,
.wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: 100%;
}

.wrapper {
  padding-right: $base-line;
  padding-left: $base-line;
  max-width: 960px;
  max-width: $layout-max-width;
  &.header--wrapper {
    overflow: hidden;
    @media #{$medium-up} {
      display: flex;
      align-items: stretch;
      .illustrated & {
        width: 100%;
        max-width: 100%;
      }
      padding: 0;
      .custom-image,
      .logo-ecole {
        display: flex;
        height: 100%;
        align-self: center;
      }
      .logo-ecole {
        position: relative;
        min-height: 300px;
        display: flex;
        align-items: center;
        &:before,
        &:after {
          position: absolute;
          content: '';
        }
        &:before {
          left: 50px;
          background: #fff;
          width: calc(100% - 50px);
          height: 100%;
        }
        &:after {
          left: -50px;
          top: 0;
          border-style: solid;
          border-width: 150px 50px;
          border-color: #fff #fff #fff transparent;
          width: 0;
          clip-path: inset(0 0 0 0);
          z-index: 2;
        }
      }
    }
  }
}

main {
  > * {
    @media #{$medium-up} {
      max-width: 80vw;
      margin: 0 auto;
    }
  }
}

// Alert
// -------------------------
.alert {
  position: sticky;
  top: 0;
  background: #d4edda;
  color: #155724;
  display: flex;
  width: 100%;
  z-index: 1;
  padding: .5em 1em;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 1em rgba(0,0,0,.2);
  border: solid 1px #c3e6cb;
}

// Header
// -------------------------

header {
  position: relative;
  z-index: 0;
  margin-bottom: $base-line*4;
  padding: 0;
  min-height: 10rem;
  text-align: center;
  border-top: 18px solid $color-grey-verydark;
  .illustrated & {
    color: #fff;
    @media #{$medium-up} {
      display: flex;
    }
    .custom-image {
      margin-left: -1em;
      margin-right: -1em;
      height: 10em;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 100% auto;
      //z-index: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      @media #{$medium-up} {
        flex-direction: column;
        min-height: 100%;
        width: 50vw;
        margin-left: 0;
        margin-right: 0;
        background-position: 50% 50%;
        background-size: cover;
      }
    }
    &:before {
      position: absolute;
      z-index: 1;
      content: '';
      left: 0;
      width: 100%;
      height: 10em;
      background: $color-primary;
      opacity: .6;
      @media #{$medium-up} {
        width: 50vw;
        height: 100%;
      }
    }
  }
  h1 {
    position: relative;
    flex-grow: 1;
    z-index: 2;
    padding: 0 .5em;
    display: block;
    min-width: 100%;
    margin: 0;
    align-self: center;
    .illustrated & {
      text-shadow: 0 0 .0625em rgba(0,0,0,.5);
    }
    @media #{$medium-up} {
      padding: 0 2em 0 0;
      display: flex;
      align-self: center;
      align-items: center;
      z-index: 2;
      align-self: flex-end;
      min-width: 10em;
      max-width: 480px;
    }
  }
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  img {
    z-index: 1;
    margin: $base-line auto;
    max-width: 12.5rem;
    max-height: 10rem;
    @media #{$medium-up} {
      margin: $base-line $base-line*6;
      max-height: 16.25rem;
    }
  }
}

h1 {
  margin: $base-line/2 0;
  padding: $base-line/2;
  line-height: 1.2;
  text-align: center;
  @media #{$small-only} {
    font-size: 1.6em;
    font-weight: normal;
  }
  @media #{$medium-up} {
    margin: 0;
    text-align: right;
  }
}

footer {
  margin: $base-line*3 0 0;
  padding: $base-line 0;
  text-align: center;
  color: #000;
  a,
  a:visited {
    color: currentColor;
  }
  @media #{$medium-up} {
    padding: 0;
  }

  &, h2 {
    color: currentColor;
  }

  border-top: 6px solid $color-primary;
  &.main-footer--illustrated {
    border-top: none;
  }
  .footer-illustration {
    display: none;
  }

  $footer_height: 14em;

  .illustrated & {
    color: #fff;
    background: $color-primary;
    @media #{$medium-up} {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
    }
    .footer-illustration {
      @media #{$medium-up} {
        display: flex;
        flex-basis: 25%;
        position: relative;
        content: '';
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        z-index: 0;
        min-height: $footer_height;
      }
      &:before {
        @media #{$medium-up} {
          position: absolute;
          z-index: -1;
          content: '';
          left: 0;
          width: 100%;
          min-height: $footer_height;
          background: $color-primary;
          opacity: .6;
        }
      }
      &:after {
        position: absolute;
        right: -2.55em;
        height: $footer_height;
        content: '';
        border-style: solid;
        border-width: $footer_height/2 3em;
        border-color: $color-primary $color-primary $color-primary transparent;
        clip-path: inset(0 2.5em 0 0);
      }
      & + .wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 1.8144em;
      }
    }
    &:after {
      @media #{$medium-up} {
        display: block;
        background: transparent;
        content: '\a0';
        flex-basis: 25%;
      }
    }
  }
}

.itn {
  font-size: 9pt;
  margin-bottom: 0;
}

.introduction {
  margin-bottom: 4em;
  ul {
    margin: 0 0 $base-line;
    padding: 0;
    list-style-type: none;
  }

  li {
    margin: 0;
    &:before {
      content: '>';
      margin-right: 0.5em;
      font-weight: bold;
      color: $color-primary;
    }
  }
}

.next-step {
  position: relative;
  overflow: hidden;
  margin: $base-line*2 0 0;
  padding: $base-line*2 0;
  text-align: center;
  //border-bottom: 6px solid $color-primary;
  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 50%;
    width: 100%;
    height: 0;
    transform: translate(-50%);;
    border-top: 25px solid #000;
    border-right: 400px solid transparent;
    border-left: 400px solid transparent;
  }
}

.warning-info {
  margin-bottom: 4em;
  > p, > h4 {
    font-size: 1.15em;
  }
}