.select-container {
  display: block;
  width: 100%;
  position: relative; 
  overflow: hidden;
  &:after{
    content: '';
    position: absolute;
    top: 50%; 
    margin-top: -3px;
    right: .75em;  
    display: block; 
    width: 0;
    height: 0;
    border-color: transparent;
    border-top-color: #444;
    border-width: 6px;
    border-style: solid;
    pointer-events: none;
  }
}
select {
  background: white;
  color: currentColor;
  border: solid 1px #777;
  display: block;
  width: 100%;
  height: auto;
  flex: 99;
  order: 2;
  min-width: 10px;
  box-sizing: border-box;
  padding: .25em 2em .25em .25em;
  font-family: inherit;
  font-size: 1em;
  border-radius: 0;
  -webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}