@mixin sassy-radio {
  position: absolute;
  left: -1000rem;

  + label {
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin-right: $base-line;
    padding: $base-line/3 $base-line * 2;
    line-height: 1;
    border: 1px solid transparent;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      height: 22px;
      width: 22px;
      border-radius: 50%;
      font-family: sans-serif;
      border: 1px solid #000;
    }

    &::after {
      content: '';
      transition: background-color 0.3s;
    }
  }

  &:focus + label {
    text-decoration: underline;
  }

  &:checked + label::after {
    content: '';
    position: absolute;
    top: 11px;
    left: 2px;
    height: 17px;
    width: 18px;
    border-radius: 50%;
    background-color: $color-grey-verydark !important;
    border: 1px solid transparent;
    text-align: center;
    transform: translateY(-50%);
  }

  &:focus + label::after {
    background-color: $sassy-accent-color;
  }

  // &:focus + label::before {
  //   border-color: $sassy-accent-color;
  // }

  &:disabled {
    + label {
      cursor: not-allowed;

      &::before {
        background-color: $sassy-disabled-color;
      }
    }

    &:checked + label::after {
      background-color: darken($sassy-disabled-color, 30%);
    }
  }
}
