table.reform-information {
  td {
    width: 50%;
    img {
      max-height: 15em;
    }
  }
  td.reform-illustration {
    text-align: center;
    padding: 0 1em;
  }
  td.reform-description {
    text-align: center;
    color: $color-grey-dark;
    a:visited {
      color: $color-grey-verydark;
    }
  }
}