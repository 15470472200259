////
/// Buttons
////

// Inspired by
// http://hugogiraudel.com/2014/03/03/another-sass-button-library/

// Configuration
$btn-name: 'button' !default;
$btn-hover: darken 0% !default;
$btn-border: darken 0% !default; // Set to false for no border
$btn-size-ratio: 1.2 !default;
$btn-schemes: (default: #000,
  success: $success,
  danger: $danger,
  warning: $warning,
  info: $color-secondary,
) !default;

// Color helper
// 1. Background-color
// 2. On hover
// 3. Border-color
@mixin button-color($color) {
  $everything-okay: true;

  // Making sure $color is a color
  @if type-of($color) !=color {
    @warn "`#{$color}` is not a color for `button-color`";
    $everything-okay: false;
  }

  // Making sure $btn-hover and $btn-border are 2 items long
  @if length($btn-hover) !=2 or length($btn-border) !=2 {
    @warn "Both `$btn-hover` and `$btn-border` should be two items long for `button-color`.";
    $everything-okay: false;
  }

  // Making sure first items from $btn-hover and $btn-border are valid functions
  @if not function-exists(nth($btn-hover, 1)) or not function-exists(nth($btn-border, 1)) {
    @warn "Either `#{nth($btn-hover, 1)}` or `#{nth($btn-border, 1)}` is not a valid function for `button-color`.";
    $everything-okay: false;
  }

  // Making sure second items from $btn-hover and $btn-border are percentages
  @if type-of(nth($btn-hover, 2)) !=number or type-of(nth($btn-border, 2)) !=number {
    @warn "Either `#{nth($btn-hover, 2)}` or `#{nth($btn-border, 2)}` is not a valid percentage for `button-color`.";
    $everything-okay: false;
  }

  // If there is no mistake
  @if $everything-okay==true {
    color: #fff;
    background-color: $color; // 1

    &:hover,
    &:active {
      // 2
      background: call(get-function(nth($btn-hover, 1)), $color, nth($btn-hover, 2));
      color: #000 !important;
      background: $color-grey-light;
    }

    @if $btn-border !=false {
      // 3
      border-color: call(get-function(nth($btn-border, 1)), $color, nth($btn-border, 2));
    }
  }
}

// Default class
// 1. Border or not border?
// 2. Large modifier
// 3. Small modifier
// 4. Bold modifier
// 5. Block modifier
// 6. Uppercase modifier
// 7. Color themes modifier
.#{$btn-name} {
  // Default styles
  border: if($btn-border !=false, 1px solid, none); // 1
  text-transform: normal;

  // Modifiers
  &--large {
    // 2
    font-size: 1em * $btn-size-ratio;
  }

  &--small {
    // 3
    font-size: 1em / $btn-size-ratio;
  }

  &--bold {
    // 4
    font-weight: bold;
  }

  &--block {
    // 5
    display: block;
    width: 100%;
  }

  &--upper {
    // 6
    text-transform: uppercase;
  }

  @each $key,
  $value in $btn-schemes {

    // 7
    &--#{$key} {
      @include button-color($value);
    }
  }
}
