
//
// Print
//
// Quelques astuces trouvées chez Alsa :
// http://www.alsacreations.com/tuto/lire/586-feuille-style-css-print-impression.html
// http://www.alsacreations.com/astuce/lire/1160-une-feuille-de-styles-de-base-pour-le-media-print.html
// https://uxdesign.cc/i-totally-forgot-about-print-style-sheets-f1e6604cfd6#.ad9m8zgbd
////

@media print {

  // Reset
  *,
  *:before,
  *:after,
  *:first-letter,
  p:first-line,
  div:first-line,
  blockquote:first-line,
  li:first-line {
      background: transparent !important;
      color: #000 !important;
      box-shadow: none !important;
      text-shadow: none !important;
  }

  // Content
  body > *:not(main) {
     // display: none;
  }
  a[href]:after {
      content: " (" attr(href) ")";
  }
  abbr[title]:after {
      content: " (" attr(title) ")";
  }

  @page {
    margin: 1cm;
  }
  html{
    font-size:13pt;
  }
  body{
    color:#000;
  }
  a,
  header a{
    color:#000;
  }
  p, blockquote {
    orphans: 3;
    widows: 3;
  }
  blockquote, ul, ol {
    page-break-inside: avoid;
  }
  h1, h2, h3, caption {
    page-break-after: avoid;
  }
  .wrapper{
    max-width: none;
    margin:0;
  }
  header{
    background: none;
  }
  nav,
  .breadcrumb{
    display:none;
  }

}
