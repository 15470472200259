////
/// Legend
////

@function url-friendly-colour($colour) {
  @return '%23' + str-slice('#{$colour}', 2, -1)
}

legend, .legend {
  position: relative;
  .icon {
    display: none;
  }
  @media #{$medium-up} {
    .icon {
      position: absolute;
      top: 50%;
      display: inline-block;
      margin-right: 1em;
      margin-left: -3em;
      width: 34px;
      height: 45px;
      vertical-align: top;
      background-repeat: no-repeat;
      transform: translateY(-50%);
    }
  }
}

.radio-illustration {
  display: flex;
  flex-wrap: wrap;
  @media #{$small-only} {
    flex-direction: column;
  }
  h2 {
    flex: 999 0 auto;
  }
  span {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    margin-bottom: $base-line;
    .icon {
      width: 50px;
      height: 22px;
      text-align: center;
      margin-top: 2px;
      margin-bottom: 2px;
    }
  }
}
